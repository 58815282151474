import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function MainMenu(props) {
    const [difficultyValue, setDifficultyValue] = useState(1);
    const [roomId, setRoomId] = useState("");

    let history = useHistory();

    return (
        <section className="jumbotron text-center">
            <div className="container">
                <h1>Come play chess!</h1>
                <p><Link to={"/computer?difficulty=" + difficultyValue} className="btn btn-primary mt-2">Play against the computer</Link></p>
                <div class="slidecontainer">
                    <input type="range" min="1" max="20" value={difficultyValue} onChange={(event) => setDifficultyValue(event.target.value)} class="slider" />
                    <p>Difficulty level: {difficultyValue}</p>
                </div>

                <br />
                <p>
                    <Link to={"/human/"} className="btn btn-primary mt-2">Create Room</Link><br />
                    <input className="form-control" type="text" onChange={ev => setRoomId(ev.target.value)} />
                    <button disabled={!roomId} onClick={() => history.push("/human/" + roomId)} className="btn btn-primary mt-2 ml-2">Join Room</button>
                </p>
            </div>
        </section>
    )
}