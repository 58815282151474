let serverUrl = null;
let websiteUrl = null;

if (process.env.NODE_ENV === "production") {
    serverUrl = "https://interactive-chess-server.herokuapp.com";
    websiteUrl = "https://chess.maxcyr.com";
} else {
    serverUrl = "http://127.0.0.1:4001";
    websiteUrl = "http://localhost:3000";
}

export { serverUrl, websiteUrl };