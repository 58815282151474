import React, { useEffect, useState } from "react";

export default function (props) {
    const [seconds, setSeconds] = useState(0);

    const displayMinutes = Math.floor(seconds / 60);
    const displaySeconds = Math.floor(seconds % 60);

    useEffect(() => {
        setSeconds(props.seconds);

        const timeoutDuration = (props.seconds % 1) * 1000;

        let timeoutId = null;
        let intervalId = null;
        if (props.isCountingDown) {
            timeoutId = setTimeout(() => {
                setSeconds(seconds => seconds - 1);
                intervalId = setInterval(() => {
                    setSeconds(seconds => seconds - 1);
                }, 1000);
            }, timeoutDuration);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, [props.seconds, props.isCountingDown]);

    return <>{displayMinutes}m {displaySeconds}s</>;
}