import React from "react";
import Timer from "./Timer";

import * as classNames from "classnames";

export default function (props) {
    const message = props.isPlayer ? "This is you" : "This is your opponent";

    return <div className={classNames("card mb-3", {
        "text-white bg-primary": props.isPlayerTurn
    })} style={{ maxWidth: "540px" }}>
        <div className="row no-gutters">
            <div className="col-md-3">
                <img src={"https://api.adorable.io/avatars/285/" + props.id} className="card-img" alt="..." />
            </div>
            <div className="col-md-9">
                <div className="card-body">
                    {props.id ? (
                        <>
                            <div>{message}</div>
                            <h5 className="card-title">{props.username}</h5>
                            <small>#{props.id}</small>
                            <p className="card-text">Color: {props.color}</p>
                            <p className="card-text">Time: <Timer seconds={props.remainingTime} isCountingDown={props.isCountingDown} /></p>
                            {/* <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
                        </>
                    ) : (
                            <>
                                <div>{message}</div>
                                <p>Not connected</p>
                            </>
                        )}
                </div>
            </div>
        </div>
    </div>;
}