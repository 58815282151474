import React from 'react';
import MainMenu from './MainMenu';
import ChessBoard from './ChessBoard';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.css';
import HumanGame from './HumanGame';
import ComputerGame from './ComputerGame';
import { Helmet } from 'react-helmet';

export default function App() {
  return (
    <>
      <Helmet titleTemplate="%s - Interactive Chess" />
      <Router>
        <Switch>
          <Route path="/" exact={true}>
            <MainMenu />
          </Route>
          <Route path="/computer">
            <ComputerGame />
          </Route>
          <Route path="/human/:roomId?">
            <HumanGame />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
