import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import "@chrisoakman/chessboardjs/dist/chessboard-1.0.0";
import "@chrisoakman/chessboardjs/dist/chessboard-1.0.0.css";


import socketIOClient from "socket.io-client";

import * as Chess from "chess.js"
import { serverUrl } from './Config';

var game = new Chess();
game.setInvinciblePiece("b8");
let board = null;
let socket = null;

/*global Chessboard */
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ChessBoard(props) {
    const [isGameOver, setIsGameOver] = useState(false);
    const [status, setStatus] = useState("Connecting");
    const [isWhite, setIsWhite] = useState(true);

    const boardRef = useRef(null);

    const query = useQuery();
    var customFen = query.get("fen");

    const playerColorName = isWhite ? "white" : "black";
    const opponentColorName = isWhite ? "black" : "white";

    if (customFen) {
        game.load(customFen);
    }

    useEffect(() => {
        socket = socketIOClient(serverUrl);

        if (props.type === "COMPUTER") {
            var difficulty = query.get("difficulty") || 1;
            socket.emit("createRoom", { type: "COMPUTER", difficulty: difficulty, customFen: customFen });
        } else if (props.type === "HUMAN") {
            if (props.roomId) {
                socket.emit("joinRoom", props.roomId);
            } else {
                socket.emit("createRoom", { type: "HUMAN" });
            }
        } else {
            throw "Invalid game type";
        }

        socket.on("move", data => {
            game.move(data);

            const highlightClass = 'highlight-' + opponentColorName;
            boardRef.current.querySelectorAll('.' + highlightClass).forEach(square => square.classList.remove(highlightClass));
            boardRef.current.querySelector('.square-' + data.from).classList.add(highlightClass);
            boardRef.current.querySelector('.square-' + data.to).classList.add(highlightClass);

            board.position(game.fen());

            if (game.game_over()) {
                setIsGameOver(true);
            }

            updateIsPlayerTurn(true);

            console.log("Move received");
        });

        socket.on("roomUpdate", room => {
            console.log("roomUpdate", room);

            if (props.onRoomUpdate) {
                props.onRoomUpdate(room, socket.id);
            }

            const isWhite = room.players.find(p => p.id === socket.id).isWhite;
            setIsWhite(isWhite);
            updateIsPlayerTurn(game.turn() === "b" && !isWhite || isWhite && game.turn() === "w");
        })

        socket.on("connect", () => {
            setStatus("Connected");
        });
        socket.on('connect_error', (error) => {
            setStatus("Error: " + error);
            console.error(error);
        });
        socket.on('connect_timeout', (timeout) => {
            setStatus("Connection timeout");
        });
        socket.on('error', (error) => {
            setStatus("Error");
            console.error(error);
        });
        socket.on('disconnect', (reason) => {
            setStatus("Disconnected:" + reason);
        });

        return () => socket.disconnect();
    }, []);

    useEffect(() => {
        var customPosition = customFen || "start";

        var config = {
            draggable: true,
            position: customPosition,
            orientation: isWhite ? "white" : "black",
            pieceTheme: '/img/chesspieces/wikipedia/{piece}.png',
            onDragStart: onDragStart,
            onDrop: onDrop,
            onSnapEnd: onSnapEnd
        }

        board = Chessboard('myBoard', config);
    }, [isWhite]);

    function onDragStart(source, piece, position, orientation) {
        if (game.game_over()) return false;

        if ((orientation === 'white' && piece.search(/^w/) === -1) ||
            (orientation === 'black' && piece.search(/^b/) === -1)) {
            return false
        }

        if ((orientation === "white" && game.turn() === "b") ||
            (orientation === "black" && game.turn() === "w")) {
            return false;
        }
    }

    function onDrop(source, target) {
        // see if the move is legal

        var move = game.move({
            from: source,
            to: target,
            promotion: 'q' // NOTE: always promote to a queen for example simplicity
        })

        // illegal move
        if (move === null) return 'snapback'

        const highlightClass = 'highlight-' + playerColorName;
        boardRef.current.querySelectorAll('.' + highlightClass).forEach(square => square.classList.remove(highlightClass));
        boardRef.current.querySelector('.square-' + source).classList.add(highlightClass);
        boardRef.current.querySelector('.square-' + target).classList.add(highlightClass);

        socket.emit('move', { source, target });
        console.log("emit move");

        updateIsPlayerTurn(false);

        if (game.game_over()) {
            setIsGameOver(true);
        }
    }

    // update the board position after the piece snap
    // for castling, en passant, pawn promotion
    function onSnapEnd() {
        board.position(game.fen())
        console.log("On snap end");
    }

    function updateIsPlayerTurn(isPlayerTurn) {
        if (props.onTurnChange) {
            props.onTurnChange(isPlayerTurn);
        }
    }
    
    return (
        <>
            {game && <style>{game.getInvinciblePieces().map(p => `div[data-square='${p}']{background-color: red;}`)}</style>}
            {isGameOver && <h1>The game is over</h1>}
            <div id="myBoard" ref={boardRef} className="m-auto" style={{ width: "400px" }}></div>
            <div>Status: {status}</div>
        </>
    );
}
