import React, { useState } from "react";
import ChessBoard from "./ChessBoard";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { websiteUrl } from "./Config";
import PlayerDetails from "./PlayerDetails";

export default function () {
    const [connectedRoom, setConnectedRoom] = useState({});
    const [playerId, setPlayerId] = useState(null);
    const [isPlayerTurn, setIsPlayerTurn] = useState(false);

    const { roomId } = useParams();

    let otherPlayer = null;
    let currentPlayer = null;

    if (connectedRoom.players) {
        currentPlayer = connectedRoom.players.filter(p => p.id === playerId)[0];
        if (connectedRoom.players.length === 2) {
            otherPlayer = connectedRoom.players.filter(p => p.id !== playerId)[0];
        }
    }

    function onRoomUpdate(room, playerId) {
        setConnectedRoom(room);
        setPlayerId(playerId);
    }

    return <>
        <Helmet>
            <title>{`Room ${connectedRoom.id}`}</title>
        </Helmet>
        <div className="container text-center">
            <div className="row">
                <div className="col-8">
                    <h1>Room: {connectedRoom.id}</h1>

                    <form className="form-inline justify-content-center mb-3 mt-3">
                        <label className="mr-1">Join with this url: </label>
                        {connectedRoom.id && <input onChange={() => {}} className="form-control shareUrlInput" type="text" value={websiteUrl + "/human/" + connectedRoom.id} onFocus={ev => ev.target.select()} />}
                    </form>

                    <ChessBoard type="HUMAN" roomId={roomId} onRoomUpdate={onRoomUpdate} onTurnChange={isPlayerTurn => setIsPlayerTurn(isPlayerTurn)} />
                </div>
                <div className="col-4">
                    <PlayerDetails {...currentPlayer} isPlayer={true} isPlayerTurn={isPlayerTurn} />
                    <PlayerDetails {...otherPlayer} isPlayer={false} isPlayerTurn={!isPlayerTurn} />
                </div>
            </div>
        </div>
    </>
}