import React, { useState } from "react";
import ChessBoard from "./ChessBoard";
import Helmet from "react-helmet";
import { websiteUrl } from "./Config";

export default function () {
    const [connectedRoom, setConnectedRoom] = useState({});

    return <>
        <Helmet>
            <title>{`Room ${connectedRoom.id}`}</title>
        </Helmet>
        <div className="container text-center">
            <h1>Room: {connectedRoom.id}</h1>

            <form className="form-inline justify-content-center mb-3 mt-3">
                <label className="mr-1">Spectate with this url: </label>
                <input className="form-control shareUrlInput" type="text" value={websiteUrl + "/human/" + connectedRoom.id} onFocus={ev => ev.target.select()} />
            </form>

            <ChessBoard type="COMPUTER" onRoomUpdate={room => setConnectedRoom(room)} />
        </div>
    </>
}